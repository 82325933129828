export const getOverviewData = () => {
  return [
    {
      sectionImage: "Overview/AllegiantStadium-LogoWhite-new.png",
      // sectionTitle: 'Allegiant Stadium',
      paragraph:
        "Create an immersive experience and leave a lasting impression on your guests when you takeover Allegiant Stadium. Utilize all event spaces to create a cohesive environment ideal for private concerts, keynotes, and receptions or think outside the box and create an intimate high touch experience for your smaller VIP audience.",
      area: "1.75M",
      reception: "65,000",
      // banquet: '300'
    },
  ];
};

export const getCarouselImages = () => {
  return [
    "AllegiantStadium/PhotosNew/allegiant-stadium-01.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-02.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-03.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-04.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-05.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-06.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-07.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-08.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-09.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-10.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-11.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-12.jpg",
    "AllegiantStadium/PhotosNew/allegiant-stadium-13.jpg",
  ];
};

export const getFieldViewImages = () => {
  return [
    "AllegiantStadium/EventPhotos/allegiant-stadium-14.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-15.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-16.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-17.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-18.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-19.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-20.jpg",
    "AllegiantStadium/EventPhotos/allegiant-stadium-21.jpg",
  ];
};

export const getLayoutImages = () => {
  return [
    "AllegiantStadium/Layout/layout-buyout-1.jpg",
    "AllegiantStadium/Layout/layout-buyout-2.jpg",
  ];
};
